import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collections } from 'src/app/util/collections.util';

import { IBook } from '../interfaces/book';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  books: IBook[];
  idUser: string;
  selecionado: IBook | number = -1;
  selecionado$: EventEmitter<IBook | number> = new EventEmitter();

  constructor(private afs: AngularFirestore,
    private auth: AngularFireAuth) {
    this.selecionado$.emit(-1);
    this.auth.currentUser.then(user => {
      this.idUser = user.uid;
      this.getBooks().subscribe(books => this.books = books);
    });
  }

  private getBooks(): Observable<IBook[]> {
    return this.afs.collection(Collections.BOOKS,
      ref => ref.where('author', '==', this.idUser)
      ).snapshotChanges()
      .pipe(map(collections => collections.map((col) => {
          const data = col.payload.doc.data() as IBook;
          data.id = col.payload.doc.id;
          return { ...data };
        }))
      );
  }
}
