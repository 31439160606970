import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private readonly updates: SwUpdate) {

    const mediaDark = window.matchMedia('(prefers-color-scheme: dark)');
    const manualTheme = localStorage.getItem('manualTheme');
    console.log(manualTheme, String(mediaDark.matches));

    if(manualTheme == null) {
      localStorage.setItem('manualTheme', String(mediaDark.matches));
      document.body.classList.toggle('dark', mediaDark.matches);
    } else if(manualTheme === 'true') {
      document.body.classList.toggle('dark', true);
    }

    this.updates.versionUpdates.subscribe(event => {
      console.log(event);

      this.updates.activateUpdate().then(() => {
        document.location.reload();
      });
    });
  }

}
